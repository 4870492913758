export default function SVGFlags({
  size = { width: '18px', height: '10px' },
  flag = 'uk',
  hasHighlight = false,
}: {
  size?: { width: string; height: string }
  flag?: string
  hasHighlight?: boolean
}) {
  if (flag === 'ch') {
    return (
      <svg
        data-v-43c92894=""
        width="26"
        height="18"
        viewBox="0 0 26 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          data-v-43c92894=""
          x="4"
          y="18"
          width="17.9999"
          height="17.9999"
          transform="rotate(-90 4 18)"
          fill="#C6001E"
        ></rect>{' '}
        <rect
          data-v-43c92894=""
          x="7.6001"
          y="10.8003"
          width="3.6"
          height="10.8003"
          transform="rotate(-90 7.6001 10.8003)"
          fill="white"
        ></rect>{' '}
        <rect
          data-v-43c92894=""
          x="11.2002"
          y="3.6001"
          width="3.6"
          height="10.8003"
          fill="white"
        ></rect>
      </svg>
    )
  } else if (flag === 'es') {
    return (
      <svg
        data-v-43c92894=""
        width="26"
        height="18"
        viewBox="0 0 26 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path data-v-43c92894="" d="M22 0H4V5H22V0Z" fill="#D0011B"></path>{' '}
        <path data-v-43c92894="" d="M22 13H4V18H22V13Z" fill="#D0011B"></path>{' '}
        <path data-v-43c92894="" d="M22 5V13H4V5H22Z" fill="#FFDD00"></path>{' '}
        <path data-v-43c92894="" d="M15 7H14V8H15V7Z" fill="#F6A623"></path>{' '}
        <path data-v-43c92894="" d="M8 7H7V8H8V7Z" fill="#F6A623"></path>{' '}
        <path data-v-43c92894="" d="M15 9H14V11H15V9Z" fill="#CCCCCC"></path>{' '}
        <path data-v-43c92894="" d="M8 9H7V11H8V9Z" fill="#CCCCCC"></path>{' '}
        <path data-v-43c92894="" d="M8 8H7V9H8V8Z" fill="#D0011B"></path>{' '}
        <path data-v-43c92894="" d="M15 8H14V9H15V8Z" fill="#D0011B"></path>{' '}
        <path data-v-43c92894="" d="M12 7H10V8H12V7Z" fill="#F6A623"></path>{' '}
        <path data-v-43c92894="" d="M13 6H9V7H13V6Z" fill="#D0011B"></path>{' '}
        <path data-v-43c92894="" d="M8 11H7V12H8V11Z" fill="#D0011B"></path>{' '}
        <path data-v-43c92894="" d="M15 11H14V12H15V11Z" fill="#D0011B"></path>{' '}
        <path data-v-43c92894="" d="M13 9H11V10H13V9Z" fill="#CCCCCC"></path>{' '}
        <path data-v-43c92894="" d="M12 11H11V12H12V11Z" fill="#D0011B"></path>{' '}
        <path data-v-43c92894="" d="M13 10H11V11H13V10Z" fill="#D0011B"></path>{' '}
        <path data-v-43c92894="" d="M13 8H11V9H13V8Z" fill="#D0011B"></path>{' '}
        <path data-v-43c92894="" d="M11 8H9V10H11V8Z" fill="#D0011B"></path>{' '}
        <path data-v-43c92894="" d="M11 11H10V12H11V11Z" fill="#F6A623"></path>{' '}
        <path data-v-43c92894="" d="M11 10H9V11H11V10Z" fill="#F6A623"></path>
      </svg>
    )
  } else {
    return (
      <svg
        data-v-43c92894=""
        width="26"
        height="18"
        viewBox="0 0 26 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path data-v-43c92894="" d="M10 0H4V18H10V0Z" fill="#1C379E"></path>{' '}
        <path data-v-43c92894="" d="M16 0H10V18H16V0Z" fill="white"></path>{' '}
        <path data-v-43c92894="" d="M22 0H16V18H22V0Z" fill="#D0011B"></path>
      </svg>
    )
  }
}
