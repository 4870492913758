import styles from './simpletextbox.module.css'
import ZbLink from '@/components/ZbLink'
import { Flex } from '@radix-ui/themes'

export default function SimpleTextBox({
  title,
  content,
  link,
  gap = '20px',
}: {
  title?: { text: string; url?: string; size?: string; underline?: string }
  content?: { text: string; size?: string }
  link?: { text: string; url: string }
  gap?: string
}) {
  let titleBox
  let textSize

  switch (title?.size) {
    case 'h1':
      titleBox = <h1 className={`${styles.simpleTextTitle}`}>{title?.text}</h1>
      break
    case 'h2':
      titleBox = <h2 className={`${styles.simpleTextTitle}`}>{title?.text}</h2>
      break
    case 'h3':
      titleBox = <h3 className={`${styles.simpleTextTitle}`}>{title?.text}</h3>
      break
    case 'h4':
      titleBox = <h4 className={`${styles.simpleTextTitle}`}>{title?.text}</h4>
      break
    case 'h5':
      titleBox = <h5 className={`${styles.simpleTextTitle}`}>{title?.text}</h5>
      break
    case 'h6':
      titleBox = <h6 className={`${styles.simpleTextTitle}`}>{title?.text}</h6>
      break
    default:
      titleBox = <h3 className={`${styles.simpleTextTitle}`}>{title?.text}</h3>
  }

  switch (content?.size) {
    case 'sm': {
      textSize = styles.simpleTextBoxTextSM
      break
    }
    case 'md': {
      textSize = styles.simpleTextBoxTextMD
      break
    }
    case 'lg': {
      textSize = styles.simpleTextBoxTextLG
      break
    }
    default: {
      textSize = styles.simpleTextBoxTextMD
    }
  }

  return (
    <Flex direction="column" gap={gap}>
      <div
        className={`${styles.simpleTextBoxTitle} ${title?.underline === 'black' ? styles.simpleTextBoxTitleBlack : styles.simpleTextBoxTitleGold}`}
      >
        {title?.url ? (
          <ZbLink to={title?.url} type="unstyled">
            {titleBox}
          </ZbLink>
        ) : (
          titleBox
        )}
      </div>
      <div className={`${textSize} ${styles.simpleTextBoxText}`}>
        {content?.text}
      </div>
      <div>
        {link?.url ? (
          <ZbLink to={link?.url} type="base">
            {link?.text}
          </ZbLink>
        ) : (
          ''
        )}
      </div>
    </Flex>
  )
}
