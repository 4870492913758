'use client'

import { useEffect, useState } from 'react'
import { Flex } from '@radix-ui/themes'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import styles from './langswitch.module.css'
import i18n from '@/i18n/routing'
import ZbLink from '@/components/ZbLink'
import { useLocale } from 'next-intl'
import { useMediaQuery } from 'react-responsive'
import SVGChevron from '@/components/SVGIcons/SVGChevron'
import clsx from 'clsx'
import { useDrupalContext } from '@/context/DrupalContext'

export default function LangSwitch() {
  const { alternates } = useDrupalContext()
  const isMaxWidth1024 = useMediaQuery({ query: '(max-width: 1024px)' })
  const alternatesObj =
    alternates?.alternates && Object.keys(alternates.alternates).length > 0
      ? alternates.alternates
      : '/'
  const locale = useLocale()
  const [isOpen, setIsOpen] = useState(false)
  const [collisionBoundary, setCollisionBoundary] = useState<Element | null>(
    null
  )

  useEffect(() => {
    if (typeof document !== 'undefined') {
      setCollisionBoundary(document.body)
    }
  }, [])

  const handleValueChange = (value: string) => {
    if (value === locale) {
      setIsOpen(false)
    }
  }

  return (
    <>
      <DropdownMenu.Root open={isOpen} onOpenChange={setIsOpen} modal={false}>
        <DropdownMenu.Trigger
          className={clsx(styles.langswitchTrigger, styles.hideDesktop)}
          aria-label="Language Selection"
        >
          <Flex
            className={styles.langSwitchSelectedValue}
            direction="row"
            gap="6px"
            align="center"
            justify="center"
          >
            {locale ? (
              <Flex direction="row" gap="4px">
                <span>{locale.toUpperCase()}</span>
              </Flex>
            ) : null}
            <SVGChevron
              size={{ width: '8px', height: '8px' }}
              direction="down"
            />
          </Flex>
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenu.Content
            sticky="always"
            side={isMaxWidth1024 ? 'top' : 'bottom'}
            align="center"
            collisionBoundary={collisionBoundary}
            className={styles.langSwitchContent}
          >
            {i18n.locales.map((lang, key) => (
              <div key={lang} className={styles.langSwitchItemContainer}>
                <DropdownMenu.Item
                  asChild
                  className={clsx(
                    styles.langSwitchItem,
                    locale === lang ? styles.langSwitchItemSelected : ''
                  )}
                  aria-label={i18n.labels[lang]}
                  onSelect={() => handleValueChange(lang)}
                >
                  <ZbLink
                    to={alternatesObj[lang] || '/'}
                    locale={lang}
                    type="unstyled"
                  >
                    {i18n.labels[lang]}
                  </ZbLink>
                </DropdownMenu.Item>
              </div>
            ))}

            <DropdownMenu.Arrow
              width={20}
              height={10}
              className={styles.dropdownArrow}
            />
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
      <Flex
        direction="row"
        gap="50px"
        justify="center"
        className={styles.mobileLangSwitch}
      >
        {i18n.locales.map((lang, key) => (
          <div
            key={key}
            className={clsx(
              styles.langSwitchItem,
              locale === lang ? styles.langSwitchItemSelected : ''
            )}
          >
            <ZbLink
              to={alternatesObj[lang] || '/'}
              locale={lang}
              type="unstyled"
              size="fit-content"
            >
              {i18n.labels[lang]}
            </ZbLink>
          </div>
        ))}
      </Flex>
    </>
  )
}
