'use client'

import ZbAccordion from '@/components/Accordion'
import styles from './accordionsubmenu.module.css'
import CountrySwitch from '@/components/CountrySwitch'
import LangSwitch from '@/components/LangSwitch'
import { Flex } from '@radix-ui/themes'
import ZbLink from '@/components/ZbLink'
import { useResources } from '@/context/ResourcesContext'

export default function AccordionSubmenu({ data }: { data?: any }) {
  const resources = useResources()
  return (
    <div className={`${styles.accordionSubmenuContainer}`}>
      <Flex direction="column" width="100%" justify="center">
        <ZbAccordion data={data} />
        <div className={`${styles.accordionSubmenuBtn}`}>
          <ZbLink to={resources['volumecalc_url']} type="button" size="large">
            <span>{resources['volumecalc_rentABox']}</span>
          </ZbLink>
        </div>
        <div className={`${styles.accordionSubmenuLangSwitch}`}>
          <LangSwitch />
        </div>
        <div className={`${styles.accordionSubmenuCountrySwitch}`}>
          <CountrySwitch />
        </div>
      </Flex>
    </div>
  )
}
