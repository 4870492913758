'use client'

import ZbContactPopup from '@/components/ContactPopup'
import styles from './floatingButton.module.css'
import { useResources } from '@/context/ResourcesContext'
import { useScrollDirection } from '@/hooks/useScrollDirection'
import { useMediaQuery } from 'react-responsive'
import { useEffect, useState } from 'react'
import { usePathname } from 'next/navigation'

import clsx from 'clsx'
import i18nConfig from '@/i18n/i18n.config'
import { useLocale } from 'next-intl'

export default function ZbFloatingContactButton(props) {
  const resources = useResources()
  const isScrollingUp = useScrollDirection()
  const isMobile = useMediaQuery({ maxWidth: 599 })
  const currentLocale = useLocale()
  const [showButtons, setShowButtons] = useState(false)
  const [hydrated, setHydrated] = useState(false)
  const countryCode = process.env.NEXT_PUBLIC_COUNTRY_CODE
  const [hideOnVE, setHideOnVE] = useState(false)
  const pathname = usePathname()
  const { locations, quicklinks } = props

  useEffect(() => {
    setHydrated(true)
  }, [])

  useEffect(() => {
    if (hydrated) {
      const isSpain = countryCode === 'es'
      const shouldShow = isSpain || !isMobile || isScrollingUp
      setShowButtons(shouldShow)

      if (
        pathname.includes(i18nConfig[currentLocale]?.veUrl) ||
        pathname.includes(i18nConfig[currentLocale]?.veEngUrl) ||
        pathname.includes(i18nConfig[countryCode]?.veUrl) ||
        pathname.includes(i18nConfig[countryCode]?.veEngUrl)
      ) {
        setHideOnVE(true)
      }
    }
  }, [isScrollingUp, isMobile, hydrated, countryCode, pathname, currentLocale])

  if (!hydrated) {
    return null
  }

  return (
    <div
      className={clsx(
        hideOnVE ? 've-hideElement' : '',
        styles.wrapper,
        !isMobile && styles.hideForMobile,
        isScrollingUp && isMobile && styles.fadeIn,
        !isScrollingUp && isMobile && styles.fadeOut
      )}
    >
      <ZbContactPopup
        display={true}
        dialogType="call"
        title={resources?.contact_cta_phone_title ?? ''}
        locations={locations}
      />
      <ZbContactPopup
        dialogType="write"
        title={resources?.contact_cta_write_title ?? ''}
        display={true}
        quicklinks={quicklinks}
      />
    </div>
  )
}
