import styles from './svgicons.module.css'

export default function SVGYoutube({
  size = { width: '18px', height: '10px' },
  color = 'white',
  bgColor = 'black',
  hasHighlight = false,
}: {
  size?: { width: string; height: string }
  color?: string
  bgColor?: string
  hasHighlight?: boolean
}) {
  return (
    <svg
      fill={bgColor}
      width={size.width}
      height={size.height}
      viewBox="0 0 600 600"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className={hasHighlight ? styles.highlightOnHover : ''}
    >
      <circle cx="300" cy="300" r="300" />
      <g>
        <path
          fill={color}
          d="M196.88,182.15c33.48-2.68,72.99-4.02,103.12-4.02h0c30.13,0,68.97,1.34,103.12,4.02,39.51,3.35,59.6,10.04,64.95,49.55,2.68,18.08,4.02,42.86,4.02,68.3h0c0,25.45-1.34,50.89-4.02,68.3-5.36,40.18-25.45,46.2-64.95,49.55-34.15,3.35-72.99,4.02-103.12,4.02h0c-30.13,0-69.64-.67-103.12-4.02-39.51-3.35-60.27-9.37-65.62-49.55-2.01-17.41-3.35-42.86-3.35-68.3h0c0-25.45,1.34-50.22,3.35-68.3,5.36-39.51,26.12-46.2,65.62-49.55h0Z"
        />
        <polygon points="265.18 248.44 354.91 300 265.18 352.23 265.18 248.44" />
      </g>
    </svg>
  )
}
