import { Flex } from '@radix-ui/themes'
import ZbLink from '@/components/ZbLink'
import ArrowLinkList from '@/components/ArrowLinkList'
import styles from './solutions.module.css'
import SimpleTextBox from '@/components/SimpleTextBox'
import BlogPreview from '@/components/BlogPreview'
import secondLevelStyles from '../secondlevel.module.css'
import clsx from 'clsx'

export default function Solutions({ data = {} }: { data: any }) {
  const currentCountry = process.env.NEXT_PUBLIC_COUNTRY_CODE
  const applyAutoHeight = currentCountry === 'es' || currentCountry === 'fr'

  return (
    <div
      className={clsx(
        secondLevelStyles.secondLevelContent,
        secondLevelStyles.secondLevelContentWiderRight
      )}
    >
      <div className={`${secondLevelStyles.mainLeftColumn}`}>
        <SimpleTextBox
          title={{
            text: data?.leftCol?.title?.text,
            size: 'h3',
            url: data?.leftCol?.title?.url,
          }}
          content={{ text: data?.leftCol?.text, size: 'md' }}
          link={{
            text: data?.leftCol?.url?.text,
            url: data?.leftCol?.url?.url,
          }}
        />
      </div>
      <Flex
        direction="row"
        className={clsx(secondLevelStyles.mainMiddleColumn)}
        width="100%"
        justify="center"
        gap="25px"
        mt="15px"
      >
        <Flex direction="column" className={`${styles.leftColumn}`} gap="10px">
          <ArrowLinkList
            elements={data?.middleCol?.leftCol?.bottom?.children}
            title={{
              text: data?.middleCol?.leftCol?.top?.title,
              url: data?.middleCol?.leftCol?.top?.url,
            }}
            columns={1}
            gapChildren="0"
            childrenClassName={
              applyAutoHeight ? 'arrowLinkListChildAutoHeight' : ''
            }
          />
        </Flex>
        <Flex className={`${styles.rightColumn}`} direction="column" gap="10px">
          <ArrowLinkList
            elements={data?.middleCol?.rightCol?.children}
            title={{
              text: data?.middleCol?.rightCol?.top?.title,
              url: data?.middleCol?.rightCol?.top?.url,
            }}
            columns={1}
            gapChildren="0"
            childrenClassName={
              applyAutoHeight ? 'arrowLinkListChildAutoHeight' : ''
            }
          />
        </Flex>
      </Flex>
      <Flex
        direction="column"
        width="100%"
        gap="20px"
        className={clsx(
          secondLevelStyles.mainRightColumn,
          styles.mainRightColumn
        )}
      >
        <div className={`${styles.mainRightColumnTitle}`}>
          <h5>{data?.rightCol?.title}</h5>
        </div>

        {data.rightCol.children.articles.map((article, index) => (
          <ZbLink key={index} to={article?.url} type="unstyled">
            <BlogPreview
              image={article?.image}
              title={article?.title}
              text={article?.text}
              date={article?.date}
            />
          </ZbLink>
        ))}
      </Flex>
    </div>
  )
}
