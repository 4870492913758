export default function SVGHamburger({
  size = { width: '18px', height: '10px' },
  color = 'black',
}: {
  size?: { width: string; height: string }
  color?: string
}) {
  return (
    <svg
      width={size.width}
      height={size.height}
      viewBox="0 0 29 15"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      fill={color}
    >
      <g stroke="none" strokeWidth="1" strokeLinecap="square">
        <g
          id="UI-hamburgerDefault"
          transform="translate(-9.000000, -17.000000)"
          stroke={color}
        >
          <path d="M37.5,17.5 L9.5,17.5 M37.5,24.5 L9.5,24.5 M37.5,31.5 L9.5,31.5" />
        </g>
      </g>
    </svg>
  )
}
