'use client'

import { useState, useEffect, useRef } from 'react'
import { Flex } from '@radix-ui/themes'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import styles from './countryswitch.module.css'
import { usePathname } from '@/i18n/routing'
import i18nConfig from '@/i18n/i18n.config'
import SVGChevron from '@/components/SVGIcons/SVGChevron'
import SVGFlags from '@/components/SVGIcons/SVGFlags'
import { useMediaQuery } from 'react-responsive'
import ZbLink from '@/components/ZbLink'
import { countryCode } from '@/lib/config'

export default function CountrySwitch() {
  const pathname = usePathname()
  const isMaxWidth1024 = useMediaQuery({ query: '(max-width: 1024px)' })
  const [collisionBoundary, setCollisionBoundary] = useState<Element | null>(
    null
  )
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef<HTMLDivElement | null>(null)
  const triggerRef = useRef<HTMLButtonElement | null>(null)

  useEffect(() => {
    if (typeof document !== 'undefined') {
      setCollisionBoundary(document.body)
    }
  }, [])

  useEffect(() => {
    const handleOutsideClick = (event: PointerEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        triggerRef.current &&
        !triggerRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false)
      }
    }

    document.addEventListener('pointerdown', handleOutsideClick)

    return () => {
      document.removeEventListener('pointerdown', handleOutsideClick)
    }
  }, [])

  return (
    <DropdownMenu.Root modal={false} open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenu.Trigger
        ref={triggerRef}
        className={styles.countryswitchTrigger}
        aria-label="Country Selection"
      >
        {countryCode ? (
          <Flex
            className={`${styles.countrySwitchSelectedValue}`}
            direction="row"
            gap="6px"
            justify="center"
            align="center"
          >
            <SVGFlags
              size={{ width: '6px', height: '6px' }}
              flag={countryCode}
            />
            <span>{countryCode?.toLocaleUpperCase()}</span>
            <SVGChevron
              size={{ width: '8px', height: '8px' }}
              direction="down"
            />
          </Flex>
        ) : null}
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          ref={dropdownRef}
          sticky="always"
          side={isMaxWidth1024 ? 'top' : 'bottom'}
          align="center"
          collisionBoundary={collisionBoundary}
          className={styles.countrySwitchContent}
        >
          {Object.entries(i18nConfig).map(
            ([code, country]) =>
              country?.name && (
                <div key={code} className={styles.countrySwitchItemContainer}>
                  <DropdownMenu.Item
                    className={styles.countrySwitchItem}
                    aria-label={country?.name}
                  >
                    <ZbLink
                      isExternal
                      to={i18nConfig[code].countryUrl || pathname}
                      locale={country?.defaultLocale}
                      type="unstyled"
                    >
                      <Flex
                        direction="row"
                        align="center"
                        justify="center"
                        height="100%"
                        gap="10px"
                      >
                        <SVGFlags
                          size={{ width: '16px', height: '16px' }}
                          flag={code}
                        />
                        {country?.name}
                      </Flex>
                    </ZbLink>
                  </DropdownMenu.Item>
                </div>
              )
          )}

          <DropdownMenu.Arrow
            width={20}
            height={10}
            className={styles.dropdownArrow}
          />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}
