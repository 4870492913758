'use client'

import ZbLink from '@/components/ZbLink'
import * as Accordion from '@radix-ui/react-accordion'
import styles from './accordion.module.css'
import { AccordionItem, AccordionProps } from './accordion.types'
import clsx from 'clsx'

const AccordionContent = ({
  item,
  level = 0,
}: {
  item: AccordionItem
  level: number
}) => {
  if (
    item?.children?.some((child) => child.children && child.children.length > 0)
  ) {
    return (
      <Accordion.Content className={styles.accordionContent}>
        <ZbAccordion
          key={`level-${level}`}
          data={item.children}
          level={level + 1}
          className={styles.nestedAccordion}
        />
      </Accordion.Content>
    )
  }

  const processedChildren = item?.children?.map((child, childIndex) => (
    <div key={childIndex} className={styles.finalNestedContainer}>
      <ZbLink to={child.url || '/'} className={styles.finalNestedItem}>
        {child.title}
      </ZbLink>
    </div>
  ))

  return (
    <Accordion.Content className={styles.accordionContent}>
      {processedChildren}
    </Accordion.Content>
  )
}

export default function ZbAccordion({
  data,
  level = 0,
  className,
}: AccordionProps & { level?: number }) {
  const accordionItems = data?.map((item, index) => {
    const itemValue = `level-${level}-${index}`

    return (
      <Accordion.Item
        key={itemValue}
        className={styles.accordionItem}
        value={itemValue}
      >
        <Accordion.Trigger className={styles.accordionTrigger}>
          {item.title}
        </Accordion.Trigger>
        <AccordionContent item={item} level={level} />
      </Accordion.Item>
    )
  })

  return (
    <div className={clsx(styles.accordionContainer, className)}>
      <Accordion.Root
        className={styles.accordionMain}
        type="single"
        collapsible
      >
        {accordionItems}
      </Accordion.Root>
    </div>
  )
}
