'use client'

import clsx from 'clsx'
import styles from './grid.module.css'
import { usePathname } from '@/i18n/routing'
import { ReactNode } from 'react'

export default function MainContainer({
  children,
  className,
}: {
  children: ReactNode
  className?: string
}) {
  const pathname = usePathname()

  const hasToolbar = pathname !== '/' && !pathname.includes('search-results')

  return (
    <main
      className={clsx(styles.mainContainer, className, {
        [styles.mainContainerWithToolbar]: hasToolbar,
      })}
    >
      {children}
    </main>
  )
}
