import { Button } from '@radix-ui/themes'
import styles from './contactPopup.module.css'
import SVGPhone from '../SVGIcons/SVGPhone'
import SVGEnvelope from '../SVGIcons/SVGEnvelope'

type ContactMobileButtonsProps = {
  title: string
  phone?: string
  email?: string
  phoneText?: string
  emailText?: string
  dialogType: 'call' | 'write'
}

export const ContactMobileButtons = ({
  title,
  phone,
  email,
  phoneText,
  emailText,
  dialogType,
}: ContactMobileButtonsProps) => {
  if (dialogType === 'call' && phone) {
    return (
      <a href={`tel:${phone}`}>
        <Button className={styles.contact__button} aria-label={title}>
          <span>
            <SVGPhone className={styles.icon} />
            {phoneText}
          </span>
        </Button>
      </a>
    )
  }

  if (dialogType === 'write' && email) {
    return (
      <a href={`mailto:${email}`}>
        <Button className={styles.contact__button} aria-label={title}>
          <span>
            <SVGEnvelope className={styles.icon} />
            {emailText}
          </span>
        </Button>
      </a>
    )
  }

  return null
}
