'use client'

import * as Popover from '@radix-ui/react-popover'
import styles from './contactPopup.module.css'
import { ContactPopupProps } from './ContactPopup.types'
import SVGClose from '../SVGIcons/SVGClose'
import { useResources } from '@/context/ResourcesContext'
import ZbLink from '@/components/ZbLink'
import { useLocale } from 'next-intl'

export const PhoneContactPopup = ({ title, locations }: ContactPopupProps) => {
  return (
    <Popover.Content className={styles.overlay} sideOffset={5}>
      <Popover.Close className={styles.close__button} aria-label="Close">
        <SVGClose
          size={{ width: '14px', height: '14px' }}
          className={styles.close__icon}
        />
      </Popover.Close>
      <div className={styles.contactPopup__wrapperPhone}>
        <p className={styles.contactPopup__title}>{title}</p>
        {locations?.map((location) => (
          <div key={location.name} className={styles.phone__wrapper}>
            <label className={styles.location__name}>{location.name}:</label>
            <a
              target="_blank"
              href={`tel:${location.phone}`}
              className={styles.phone}
            >
              {location.phone}
            </a>
          </div>
        ))}
      </div>
    </Popover.Content>
  )
}

export const EmailContactPopup = ({
  title,
  popUpText,
  quicklinks,
}: ContactPopupProps) => {
  const resources = useResources()
  const locale = useLocale()
  const contactUrl = resources?.contact_cta_contactform_url?.replace(
    new RegExp(`^.*?/${locale}/`),
    '/'
  )

  return (
    <Popover.Content
      className={styles.overlay}
      sideOffset={-90}
      align="start"
      side="left"
      avoidCollisions={false}
    >
      <Popover.Close className={styles.close__button} aria-label="Close">
        <SVGClose
          size={{ width: '14px', height: '14px' }}
          className={styles.close__icon}
        />
      </Popover.Close>
      <div className={styles.contactPopup__wrapperEmail}>
        <div className={styles.content}>
          <p className={styles.contactPopup__title}>{title}</p>
          <div className={styles.message__wrapper}>
            <p className={styles.email__message}>{popUpText}</p>
            <span className={styles.action_button__wrapper}>
              <ZbLink type="button" isEmail to={quicklinks?.mail} size="medium">
                <span>{resources?.contact_cta_write_email}</span>
              </ZbLink>
              <ZbLink size="medium" to={contactUrl} type="button">
                <span>{resources?.contact_cta_write_contactform}</span>
              </ZbLink>
            </span>
          </div>
        </div>
      </div>
    </Popover.Content>
  )
}
