'use client'

import React, { useState } from 'react'
import styles from './ZbAppFooter.module.css'
import ZbLink from '@/components/ZbLink'
import clsx from 'clsx'
import { Box, Flex, Grid, IconButton } from '@radix-ui/themes'
import SVGFacebook from '@/components/SVGIcons/SVGFacebook'
import SVGLinkedin from '@/components/SVGIcons/SVGLinkedin'
import SVGYoutube from '@/components/SVGIcons/SVGYoutube'
import SVGInstagram from '@/components/SVGIcons/SVGInstagram'
import SVGChevron from '@/components/SVGIcons/SVGChevron'

export default function ZbAppFooter({
  footerData,
  legalData,
  socialLinks,
  copyright,
  fixed = false,
}) {
  const toTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }
  const SubSection = ({
    className,
    data,
    columns = 1,
  }: {
    className?: string
    data: { title: string; items: { title: string; url: string }[] }
    columns?: number
  }) => {
    const { title, items } = data
    const [isHidden, setIsHidden] = useState(true)

    const toggleHide = () => {
      setIsHidden(!isHidden)
    }

    return (
      <Box className={className}>
        <Grid columns="2" gap="0">
          <Box
            onClick={toggleHide}
            gridColumn="span 2"
            className={styles.zbAppFooterTitle}
          >
            {title}
          </Box>
          <Grid
            className={clsx(styles.zbAppFooterLinks, isHidden && styles.hide)}
            columns="2"
            gridColumn="span 2"
            gap="1"
          >
            {items.map(({ title, url }) => (
              <Box key={title} gridColumn={columns === 1 ? 'span 2' : 'span 1'}>
                <ZbLink
                  className={clsx(
                    styles.zbUnderlineOnHover,
                    styles.zbAppFooterLink
                  )}
                  to={`${url}/`}
                >
                  {title}
                </ZbLink>
              </Box>
            ))}
          </Grid>
        </Grid>
      </Box>
    )
  }

  const SocialIcon = ({ url, IconComponent, name }) => (
    <a target="_blank" href={url}>
      <IconComponent
        size={{ width: '32px', height: '32px' }}
        name={name}
        hasHighlight={true}
        color={'black'}
        bgColor={'white'}
      />
    </a>
  )

  const SocialSection = ({ className, urls, title }) => {
    const { facebook, linkedin, youtube, instagram } = urls

    return (
      <Box className={className}>
        <Box pb="2" className={styles.zbAppFooterTitle}>
          {title}
        </Box>
        <Flex
          mt="4"
          gap="4"
          justify={{ initial: 'center', md: 'start' }}
          className={styles.zbAppFooterSocialLinks}
        >
          {facebook && (
            <SocialIcon
              url={facebook}
              IconComponent={SVGFacebook}
              name="social-facebook"
            />
          )}
          {linkedin && (
            <SocialIcon
              url={linkedin}
              IconComponent={SVGLinkedin}
              name="social-linkedin"
            />
          )}
          {youtube && (
            <SocialIcon
              url={youtube}
              IconComponent={SVGYoutube}
              name="social-youtube"
            />
          )}
          {instagram && (
            <SocialIcon
              url={instagram}
              IconComponent={SVGInstagram}
              name="social-instagram"
            />
          )}
        </Flex>
      </Box>
    )
  }

  return (
    <footer
      className={styles.zbAppFooter}
      style={{ position: fixed ? 'fixed' : 'relative' }}
    >
      <IconButton
        radius={'full'}
        className={styles.zbMoveToTopButton}
        onClick={() => toTop()}
      >
        <SVGChevron direction="up" />
      </IconButton>
      <Box className={styles.zbAppFooterBackground}>
        <Box className={clsx(styles.zbAppFooterUpper, styles.footerMaxWidth)}>
          <Grid
            mx={{ initial: '40px', md: '105px' }}
            gap="1"
            gapY="4"
            columns="10"
          >
            {footerData.map((data, index) => (
              <Box
                gridColumn={{
                  initial: 'span 10',
                  xs: 'span 10',
                  sm: 'span 10',
                  md: 'span 5',
                  lg: index <= 1 ? 'span 3' : 'span 2',
                  xl: index <= 1 ? 'span 3' : 'span 2',
                }}
                key={data?.url}
              >
                <SubSection columns={index === 1 ? 2 : 1} data={data} />
              </Box>
            ))}

            <Box
              gridColumn={{ initial: 'span 10', md: 'span 5', lg: 'span 2' }}
            >
              <SocialSection
                className={styles.zbFooterSocialMedia}
                urls={socialLinks.data}
                title={socialLinks.title}
              />
            </Box>
          </Grid>
        </Box>
      </Box>

      <Box className={styles.zbAppFooterBottomBackground}>
        <Box
          py="5"
          className={clsx(styles.zbAppFooterBottom, styles.footerMaxWidth)}
        >
          <Flex
            mx={{ initial: '40px', md: '105px' }}
            gap="5"
            direction={{ initial: 'column', md: 'row' }}
            justify="between"
          >
            <Box className={styles.zbAppFooterCopyright}>
              {copyright} {new Date().getFullYear()}
            </Box>
            <Flex
              justify={{ md: 'end', initial: 'between' }}
              flexGrow="0"
              gap={{ initial: '4', md: '9' }}
            >
              {legalData?.map(({ title, url }) => (
                <Box key={title}>
                  <ZbLink className={styles.zbAppFooterLegalLink} to={url}>
                    {title}
                  </ZbLink>
                </Box>
              ))}
            </Flex>
          </Flex>
        </Box>
      </Box>
    </footer>
  )
}
