import { useEffect, useState } from 'react'
import { Autocomplete, TextField } from '@mui/material'
import { useRouter } from 'next/navigation'
import { useAppSettings } from '@/context/AppSettingsContext'
import SVGSearch from '@/components/SVGIcons/SVGSearch'
import SVGClose from '@/components/SVGIcons/SVGClose'
import styles from './SearchInput.module.css'
import DOMPurify from 'isomorphic-dompurify'
import ZbLink from '@/components/ZbLink'
import { useLocale } from 'next-intl'
import { wrapTables } from '@/hooks/useSanitizedHtml'

interface SearchResult {
  id: string
  attributes: {
    title: string
    drupal_internal__nid: string
    field_meta_description?: string
    field_preview_lead?: {
      processed: string
    }
    path?: {
      alias: string
    }
  }
}

export default function SearchInput({ onClose }: { onClose: () => void }) {
  // TODO: check if this state is required
  const [isExpanded, setIsExpanded] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState<SearchResult[]>([])
  const [open, setOpen] = useState(false)

  const { appSettings } = useAppSettings()
  const appTranslations = appSettings?.translations || {}
  const locale = useLocale()
  const router = useRouter()

  const handleInputChange = async (_: any, newInputValue: string) => {
    setInputValue(newInputValue)
    setOpen(true)

    if (newInputValue.length >= 1) {
      try {
        const response = await fetch(
          `${process.env.NEXT_PUBLIC_DRUPAL_BASE_URL}/${locale}/jsonapi/index/search?filter[fulltext]=${encodeURIComponent(newInputValue)}&filter[langcode]=${locale}&page[limit]=15`,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        const data = await response.json()
        setOptions(data?.data || [])
      } catch (error) {
        console.error('Error fetching search results:', error)
        setOptions([])
      }
    } else {
      setOptions([])
    }
  }

  const handleChange = (_: any, newValue: SearchResult | null) => {
    if (newValue?.attributes?.drupal_internal__nid) {
      setOpen(false)
      if (
        newValue.attributes.drupal_internal__nid === appSettings?.frontPageNid
      ) {
        router.push('/')
      } else {
        router.push(
          `/${locale}${newValue.attributes?.path?.alias || `/node/${newValue.attributes.drupal_internal__nid}`}`
        )
      }
      handleClose()
    }
  }

  const handleSearchIconClick = () => {
    setIsExpanded(true)
  }

  const handleClose = () => {
    setIsExpanded(false)
    setInputValue('')
    setOptions([])
    onClose()
  }

  const handleSearch = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.KeyboardEvent
  ) => {
    e.preventDefault()
    if (inputValue.trim()) {
      router.push(
        `/${locale}/search-results?q=${encodeURIComponent(inputValue.trim())}`
      )
      setTimeout(() => {
        handleClose()
      }, 100)
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && inputValue.trim()) {
      handleSearch(event)
    }
  }

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleClose()
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.searchInputContainer}>
      <div className={styles.inputContainer}>
        <Autocomplete
          open={open && inputValue.trim() !== ''}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          inputValue={inputValue}
          onInputChange={handleInputChange}
          onChange={handleChange}
          options={options}
          slotProps={{
            popper: {
              className: 'SearchInputPopper',
            },
          }}
          getOptionLabel={(option: SearchResult) =>
            option.attributes?.title || ''
          }
          renderOption={(props, option) => {
            const { key, ...otherProps } = props
            return (
              <li key={option.id} {...otherProps}>
                <div className={styles.resultOption}>
                  <div className={styles.resultTitle}>
                    {option.attributes.title}
                  </div>
                  <div
                    className={styles.resultSubtitle}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        wrapTables(
                          option.attributes.field_meta_description ||
                            option.attributes.field_preview_lead?.processed ||
                            ''
                        )
                      ),
                    }}
                  />
                </div>
              </li>
            )
          }}
          noOptionsText=""
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              variant="outlined"
              placeholder={appTranslations[`${locale}_search_inputPlaceholder`]}
              onKeyDown={handleKeyDown}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <SVGSearch
                    size={{ width: '22px', height: '22px' }}
                    color="var(--primary-2)"
                    className={styles.searchIcon}
                  />
                ),
              }}
            />
          )}
          className={styles.SearchInputAutocomplete}
          blurOnSelect={false}
          clearOnBlur={false}
        />
        <ZbLink
          to={`/search-results?q=${encodeURIComponent(inputValue.trim())}`}
          onClick={handleSearch}
          className={styles.searchButton}
          type="button"
          size="large"
        >
          {appTranslations[`${locale}_search_btn`]}
        </ZbLink>
      </div>
      <div className={styles.actionsWrapper}>
        <button
          type="button"
          onClick={handleClose}
          className={styles.closeButton}
        >
          <SVGClose size={{ width: '16px', height: '16px' }} color="#cecece" />
        </button>
      </div>
    </div>
  )
}
