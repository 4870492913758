'use client'

import * as Popover from '@radix-ui/react-popover'
import { useMediaQuery } from 'react-responsive'
import styles from './contactPopup.module.css'
import { ContactPopupProps } from './ContactPopup.types'
import SVGPhone from '../SVGIcons/SVGPhone'
import SVGEnvelope from '../SVGIcons/SVGEnvelope'
import { EmailContactPopup, PhoneContactPopup } from './ContactPopup.partials'
import { Button } from '@radix-ui/themes'
import { useResources } from '@/context/ResourcesContext'
import { useAppSettings } from '@/context/AppSettingsContext'
import { ContactMobileButtons } from './ContactMobileButtons'

export default function ZbContactPopup({
  dialogType,
  title,
  locations,
  display,
}: ContactPopupProps) {
  const isMobile = useMediaQuery({ maxWidth: 599 })
  const resources = useResources()
  const { appSettings } = useAppSettings()

  if (!display) return null

  return (
    <Popover.Root>
      {isMobile ? (
        <ContactMobileButtons
          title={title}
          phone={appSettings?.quicklinks?.phone}
          email={appSettings?.quicklinks?.mail}
          phoneText={resources?.quicklink_phoneBtnText}
          emailText={resources?.quicklink_emailBtnText}
          dialogType={dialogType}
        />
      ) : (
        <>
          {dialogType === 'call' && (
            <>
              <Popover.Trigger asChild>
                <Button className={styles.contact__button} aria-label={title}>
                  <span>
                    <SVGPhone className={styles.icon} />
                    {resources?.quicklink_phoneBtnText}
                  </span>
                </Button>
              </Popover.Trigger>
              <Popover.Portal>
                <PhoneContactPopup
                  title={title}
                  locations={locations}
                  dialogType={dialogType}
                  popUpText={resources?.contact_cta_write_text}
                  display={display}
                />
              </Popover.Portal>
            </>
          )}

          {dialogType === 'write' && (
            <>
              <Popover.Trigger asChild>
                <Button className={styles.contact__button} aria-label={title}>
                  <span>
                    <SVGEnvelope className={styles.icon} />
                    {resources?.quicklink_emailBtnText}
                  </span>
                </Button>
              </Popover.Trigger>
              <Popover.Portal>
                <EmailContactPopup
                  title={title}
                  popUpText={resources?.contact_cta_write_text}
                  dialogType={dialogType}
                  display={display}
                  quicklinks={appSettings?.quicklinks}
                />
              </Popover.Portal>
            </>
          )}
        </>
      )}
    </Popover.Root>
  )
}
