import { Grid } from '@radix-ui/themes'
import secondlevelStyles from '../secondlevel.module.css'
import styles from './hamburgersubmenu.module.css'
import SimpleTextBox from '@/components/SimpleTextBox'
import clsx from 'clsx'

export default function HamburgerSubmenu({ data }: { data?: any }) {
  return (
    <div className={clsx(secondlevelStyles.secondLevelContentTwoColumns)}>
      <div className={secondlevelStyles.mainLeftColumn}>
        <SimpleTextBox
          title={{
            text: data?.leftCol?.title?.text,
            size: 'h3',
            url: data?.leftCol?.title?.url,
          }}
          content={{ text: data?.leftCol?.text, size: 'md' }}
          link={{
            url: data?.leftCol?.url?.url,
            text: data?.leftCol?.url?.text,
          }}
        />
      </div>
      <Grid
        columns="repeat(3, 1fr)"
        mt="4rem"
        maxWidth="100%"
        gapY="76px"
        className={styles.aboutUsGrid}
      >
        {data?.middleCol?.leftCol?.bottom?.children?.map((child, index) => (
          <SimpleTextBox
            key={index}
            gap="10px"
            title={{
              text: child?.title,
              url: child?.url,
              size: 'h6',
              underline: 'black',
            }}
            content={{
              text: child?.text,
              size: 'sm',
            }}
          />
        ))}
      </Grid>
    </div>
  )
}
