import { useMediaQuery } from 'react-responsive'
import SVGClose from '@/components/SVGIcons/SVGClose'
import Solutions from '@/components/ZbNavigation/SecondLevel/Solutions'
import Services from '@/components/ZbNavigation/SecondLevel/Services'
import Locations from '@/components/ZbNavigation/SecondLevel/Locations'
import TipsTricks from '@/components/ZbNavigation/SecondLevel/TipsTricks'
import HamburgerSubmenu from '@/components/ZbNavigation/SecondLevel/HamburgerSubmenu'
import styles from './secondlevel.module.css'
import AccordionSubmenu from '@/components/ZbNavigation/SecondLevel/AccordionSubmenu'
import { NAV_MENU_IDS } from '@/components/ZbNavigation/navigationIDs'
import clsx from 'clsx'
import { usePathname } from '@/i18n/routing'

export default function SecondLevel({
  data = {},
  altNavData = {},
  secondLevelType = '',
  isScrolled,
  closeNavigation = () => {},
}: {
  data: object
  altNavData: object
  secondLevelType: string
  isScrolled: boolean
  closeNavigation: (value: string) => void
}) {
  const pathname = usePathname()
  const handleNavClosing = (newVal: string) => {
    closeNavigation(newVal)
  }
  const firstPathElement = `/${pathname.split('/')[1]}`

  let content
  let useAccordion = false

  const isMaxWidth1280 = useMediaQuery({ query: '(max-width: 1280px)' })

  switch (secondLevelType) {
    case NAV_MENU_IDS.SOLUTIONS:
      content = <Solutions data={data} />
      break
    case NAV_MENU_IDS.SERVICES:
      content = <Services data={data} />
      break
    case NAV_MENU_IDS.LOCATIONS:
      content = <Locations data={data} />
      break
    case NAV_MENU_IDS.TIPS_TRICKS:
      content = <TipsTricks data={data} />
      break
    case NAV_MENU_IDS.HAMBURGER_MENU:
      if (isMaxWidth1280) {
        useAccordion = true
        content = <AccordionSubmenu data={altNavData} />
      } else {
        useAccordion = false
        content = <HamburgerSubmenu data={data} />
      }
      break
    default:
      content = null
      break
  }

  let secondLevelTop = styles.NavSecondLevelTop
  if (!isScrolled) {
    if (
      firstPathElement !== '/' &&
      !pathname.includes('search-results') &&
      !pathname.includes('cookies') &&
      !pathname.includes('/404')
    ) {
      secondLevelTop = styles.NavSecondLevelTopToolbar
    }
  } else if (isScrolled) {
    secondLevelTop = styles.NavSecondLevelSticky

    if (
      firstPathElement !== '/' &&
      !pathname.includes('search-results') &&
      !pathname.includes('cookies') &&
      !pathname.includes('/404')
    ) {
      secondLevelTop = styles.NavSecondLevelStickyToolbar
    }
  }

  return (
    <>
      <div
        className={styles.overlay}
        onClick={() => handleNavClosing('')}
        onMouseEnter={() => handleNavClosing('')}
      />
      <div
        className={clsx(
          !useAccordion
            ? styles.NavSecondLevel
            : styles.NavSecondLevelAccordion,
          secondLevelTop
        )}
      >
        {!useAccordion && (
          <button
            className={styles.NavSecondLevelClose}
            onClick={() => handleNavClosing('')}
          >
            <SVGClose
              size={{
                width: '16px',
                height: '16px',
              }}
              color="#d2d2d2"
            />
          </button>
        )}

        {content}
      </div>
    </>
  )
}
