import { Flex } from '@radix-ui/themes'
import ArrowLinkList from '@/components/ArrowLinkList'
import styles from './locations.module.css'
import SimpleTextBox from '@/components/SimpleTextBox'
import secondLevelStyles from '../secondlevel.module.css'

export default function Locations({ data }: { data?: any }) {
  return (
    <div className={secondLevelStyles.secondLevelContent}>
      <div className={`${secondLevelStyles.mainLeftColumn}`}>
        <SimpleTextBox
          title={{
            text: data?.leftCol?.title?.text,
            size: 'h3',
            url: data?.leftCol?.title?.url,
          }}
          content={{ text: data?.leftCol?.text, size: 'md' }}
          link={{
            text: data?.leftCol?.url?.text,
            url: data?.leftCol?.url?.url,
          }}
        />
      </div>
      <Flex
        direction="row"
        className={`${secondLevelStyles.mainMiddleColumn}`}
        width="100%"
        mt="12px"
      >
        <ArrowLinkList
          elements={data?.middleCol?.leftCol?.bottom?.children}
          className={styles.LocationsArrowLinkList}
          title={{
            text: data?.middleCol?.leftCol?.top?.title,
          }}
          columns={2}
        />
      </Flex>
      <Flex
        direction="column"
        width="100%"
        mt="12px"
        gap="20px"
        className={`${secondLevelStyles.mainRightColumn}`}
      >
        <div className={`${styles.mainRightColumnTitle}`}>
          <h4>{data?.rightCol?.title}</h4>
        </div>
        <Flex direction="column" gap="20px">
          <ArrowLinkList elements={data?.rightCol?.children} columns={1} />
        </Flex>
      </Flex>
    </div>
  )
}
