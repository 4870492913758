import styles from './imagetextbox.module.css'
import ZbLink from '@/components/ZbLink'
import ZbImage from '@/components/ZbImage'
import { Flex } from '@radix-ui/themes'

export default function ImageTextBox({
  image = { url: '', size: { w: 320, h: 250 }, subtitle: '' },
  action = { text: '', url: '', type: 'unstyled' },
  redirect = false,
  imgZoom = false,
  title = { text: '', size: 'h4' },
  text = '',
}: {
  image: {
    url: string
    size?: { w: number; h: number }
    subtitle?: string
  }
  action?: { text: string; url: string; type: string }
  redirect?: boolean
  imgZoom?: boolean
  title: { text: string; size: string }
  text: string
}) {
  let titleBox
  if (title.size === 'h1') {
    titleBox = <h1>title.text</h1>
  }

  switch (title.size) {
    case 'h1':
      titleBox = <h1>{title.text}</h1>
      break
    case 'h2':
      titleBox = <h2>{title.text}</h2>
      break
    case 'h3':
      titleBox = <h3>{title.text}</h3>
      break
    case 'h4':
      titleBox = <h4>{title.text}</h4>
      break
    case 'h5':
      titleBox = <h5>{title.text}</h5>
      break
    case 'h6':
      titleBox = <h6>{title.text}</h6>
      break
  }

  return (
    <div className={`${styles.imageTextBoxContainer}`}>
      <Flex
        direction="column"
        gap="10px"
        className={`${styles.imageTextBoxContent}`}
      >
        <div className={`${styles.imageTextBoxImage} ${styles.zoomableImg}`}>
          <ZbImage
            src={image.url}
            width={image?.size?.w}
            height={image?.size?.h}
            zoomImage={imgZoom}
            alt={titleBox}
          />
        </div>
        {image?.subtitle ? <div>{image?.subtitle}</div> : ''}
        <div className={`${styles.imageTextBoxTitle}`}>{titleBox}</div>
        <div className={`${styles.imageTextBoxText}`}>{text}</div>
        <ZbLink to={action?.url} type="button">
          <span>{action?.text}</span>
        </ZbLink>
      </Flex>
    </div>
  )
}
