import styles from './svgicons.module.css'
export default function SVGInstagram({
  size = { width: '18px', height: '10px' },
  color = 'white',
  bgColor = 'black',
  hasHighlight = false,
}: {
  size?: { width: string; height: string }
  color?: string
  bgColor?: string
  hasHighlight?: boolean
}) {
  return (
    <svg
      id="a"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 600 600"
      width={size.width}
      height={size.height}
    >
      <defs>
        <style>
          {`.b{fill:${bgColor};}.c{fill:${color};fill-rule:evenodd;}`}
        </style>
      </defs>
      <circle
        className={`b ${hasHighlight ? styles.highlightOnHover : ''}`}
        cx="300"
        cy="300"
        r="300"
      />
      <path
        className="c"
        d="M219.61,125.9h160.77c51.65,0,94.13,42.48,94.13,94.13v159.94c0,51.65-42.48,94.13-94.13,94.13H219.61c-51.65,0-94.13-42.48-94.13-94.13V220.03c0-51.65,42.48-94.13,94.13-94.13h0Zm174.1,60.81h0c10.83,0,19.99,9.16,19.99,19.99h0c0,10.83-9.16,19.99-19.99,19.99h0c-11.66,0-19.99-9.16-19.99-19.99h0c0-10.83,8.33-19.99,19.99-19.99h0Zm-94.13,18.33h.83c51.65,0,94.96,43.32,94.96,94.96h0c0,52.48-43.32,94.96-94.96,94.96h-.83c-51.65,0-94.13-42.48-94.13-94.96h0c0-51.65,42.48-94.96,94.13-94.96h0Zm0,32.49h.83c34.15,0,62.48,28.32,62.48,62.48h0c0,34.99-28.32,63.31-62.48,63.31h-.83c-34.15,0-62.48-28.32-62.48-63.31h0c0-34.15,28.32-62.48,62.48-62.48h0Zm-79.14-81.64h159.11c35.82,0,64.98,29.16,64.98,64.98v158.28c0,35.82-29.16,64.98-64.98,64.98H220.45c-35.82,0-64.98-29.16-64.98-64.98V220.86c0-35.82,29.16-64.98,64.98-64.98h0Z"
      />
    </svg>
  )
}
