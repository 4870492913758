import clsx from 'clsx'
import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import { ReactNode, useEffect, useRef, useState } from 'react'
import SecondLevel from '@/components/ZbNavigation/SecondLevel'
import { NAV_MENU_IDS } from '@/components/ZbNavigation/navigationIDs'
import navStyles from '../navigation.module.css'

export default function FirstLevel({
  alternateNav = {},
  children,
  closeNavigation,
  firstLevelData = {},
  isScrolled,
  parentKey,
  isActive = false,
}: {
  alternateNav?: any
  children: ReactNode
  closeNavigation: (value: string) => void
  firstLevelData: any
  isScrolled: boolean
  openOnClick?: boolean
  parentKey: number
  isActive?: boolean
}) {
  const [currentPath, setCurrentPath] = useState('')
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false)
  const triggerRef = useRef<HTMLButtonElement | null>(null)
  const menuRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    setCurrentPath(window?.location?.pathname)

    const handlePopState = () => {
      setCurrentPath(window?.location?.pathname)
    }

    const handleClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement

      if (target.id === 'logo') {
        setCurrentPath('/')
      } else if (target.tagName === 'A' && target.getAttribute('href')) {
        const href = target.getAttribute('href')
        if (href && window?.location?.pathname.includes(href)) {
          setCurrentPath(window?.location?.pathname)
        }
      }
    }

    window.addEventListener('popstate', handlePopState)
    document.addEventListener('click', handleClick)

    return () => {
      window.removeEventListener('popstate', handlePopState)
      document.removeEventListener('click', handleClick)
    }
  }, [])

  const handleTriggerClick = () => {
    if (firstLevelData?.drupalId === NAV_MENU_IDS.HAMBURGER_MENU) {
      setIsHamburgerOpen((prev) => !prev)
    }
  }

  const handleCloseInteractions = (event: MouseEvent) => {
    const target = event.target as HTMLElement
    const isOverlay = Array.from(target.classList).some((className) =>
      className.includes('overlay')
    )
    const isLink = Array.from(target.classList).some((className) =>
      className.includes('finalNestedItem')
    )
    const isMenuRefNull = !menuRef.current
    const isClickOutsideMenu =
      menuRef.current && !menuRef.current.contains(target)
    const isClickOutsideTrigger =
      triggerRef.current && !triggerRef.current.contains(target)

    const isCloseInteraction =
      isHamburgerOpen &&
      (isOverlay ||
        isLink ||
        ((isMenuRefNull || isClickOutsideMenu) && isClickOutsideTrigger))

    if (isCloseInteraction) {
      setIsHamburgerOpen(false)
    }
  }

  const handleMouseLeave = () => {
    closeNavigation('')
    setIsHamburgerOpen(false)
  }

  useEffect(() => {
    const mainElement = document.querySelector('main')

    if (isHamburgerOpen) {
      document.body.classList.add(navStyles.noScroll)
      mainElement.classList.add(navStyles.noScroll)
      document.addEventListener('click', handleCloseInteractions)
    } else {
      mainElement.classList.remove(navStyles.noScroll)
      document.body.classList.remove(navStyles.noScroll)
    }

    return () => {
      document.removeEventListener('click', handleCloseInteractions)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHamburgerOpen])

  useEffect(() => {
    const links = menuRef.current?.querySelectorAll('a')
    const handleLinkClick = () => {
      setIsHamburgerOpen(false)
    }

    links?.forEach((link) => {
      link.addEventListener('click', handleLinkClick)
    })

    return () => {
      links?.forEach((link) => {
        link.removeEventListener('click', handleLinkClick)
      })
    }
  }, [isHamburgerOpen])

  const handleButtonClick = () => {
    if (triggerRef.current) {
      if (isHamburgerOpen) {
        closeNavigation('')
        setIsHamburgerOpen(false)
      } else {
        triggerRef.current.click()
      }
    }
  }

  return (
    <NavigationMenu.Item
      key={parentKey}
      className={clsx(
        navStyles.NavFirstLevelItem,
        firstLevelData?.drupalId !== NAV_MENU_IDS.HAMBURGER_MENU
          ? 'lgDisplayNone'
          : ''
      )}
    >
      {firstLevelData?.drupalId === NAV_MENU_IDS.HAMBURGER_MENU ? (
        <>
          <NavigationMenu.Trigger
            ref={triggerRef}
            className={clsx(
              navStyles.NavFirstLevelTitle,
              navStyles.NavTriggerUnstyle,
              currentPath === firstLevelData?.url &&
                navStyles.NavFirstLevelActive,
              firstLevelData?.drupalId === NAV_MENU_IDS.HAMBURGER_MENU &&
                navStyles.NavHamburger
            )}
            onClick={handleTriggerClick}
            aria-expanded={isHamburgerOpen}
            aria-controls={`submenu-${parentKey}`}
          >
            {children}
          </NavigationMenu.Trigger>
          <button
            className={clsx(
              navStyles.NavTriggerUnstyle,
              navStyles.NavHamburgerButton
            )}
            onClick={handleButtonClick}
          >
            {children}
          </button>
        </>
      ) : (
        <NavigationMenu.Trigger
          className={clsx(
            navStyles.NavFirstLevelTitle,
            navStyles.NavTriggerUnstyle,
            isActive && navStyles.NavFirstLevelActive
          )}
          aria-controls={`submenu-${parentKey}`}
        >
          {children}
        </NavigationMenu.Trigger>
      )}
      <NavigationMenu.Content ref={menuRef} onMouseLeave={handleMouseLeave}>
        <SecondLevel
          data={firstLevelData}
          altNavData={alternateNav}
          secondLevelType={firstLevelData?.drupalId}
          isScrolled={isScrolled}
          closeNavigation={closeNavigation}
        />
      </NavigationMenu.Content>
    </NavigationMenu.Item>
  )
}
