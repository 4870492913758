'use client'

import Script from 'next/script'
import { COOKIEBOT_CONFIG } from '@/lib/config'

export default function CookieBotInitializer({ locale }: { locale: string }) {
  if (!COOKIEBOT_CONFIG.ID) {
    return null
  }

  return (
    <>
      <Script
        id={COOKIEBOT_CONFIG.ELEMENTS.SCRIPT_ID}
        src="https://consent.cookiebot.com/uc.js"
        data-cbid={COOKIEBOT_CONFIG.ID}
        data-culture={locale}
        data-blockingmode="auto"
        strategy="afterInteractive" // Changed from beforeInteractive
      />
      <noscript>
        <iframe
          src={`https://consent.cookiebot.com/${COOKIEBOT_CONFIG.ID}/cd.js?culture=${locale}`}
          height="0"
          width="0"
          style={{ border: '0' }}
        ></iframe>
      </noscript>
    </>
  )
}
