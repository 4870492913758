'use client'

import { Flex } from '@radix-ui/themes'
import BlogPreview from '@/components/BlogPreview'
import styles from './tipstricks.module.css'
import SimpleTextBox from '@/components/SimpleTextBox'
import PillButton from '@/components/buttons/PillButton'
import ZbLink from '@/components/ZbLink'
import secondLevelStyles from '../secondlevel.module.css'
import clsx from 'clsx'
import { useAppSettings } from '@/context/AppSettingsContext'
import { useLocale } from 'next-intl'

interface Article {
  url: string
  image: string
  title: string
  text: string
  date: string
}

interface Link {
  url: string
  title: string
}

interface Category {
  title: string
  url: string
}

export default function TipsTricks({ data }: { data?: any }) {
  const { appSettings } = useAppSettings()
  const locale = useLocale()

  return (
    <div className={clsx(secondLevelStyles.secondLevelContent, secondLevelStyles.secondLevelContentWiderRight)}>
      <div className={secondLevelStyles.mainLeftColumn}>
        <SimpleTextBox
          title={{
            text: data?.leftCol?.title?.text,
            size: 'h3',
            url: data?.leftCol?.title?.url,
          }}
          content={{ text: data?.leftCol?.text, size: 'md' }}
          link={{
            url: data?.leftCol?.url?.url,
            text: data?.leftCol?.url?.text,
          }}
        />
      </div>
      <Flex
        direction="column"
        className={clsx(
          secondLevelStyles.mainMiddleColumn,
          styles.mainMiddleColumn
        )}
        justify="start"
        mt="12px"
        gap="20px"
      >
        <div className={`${styles.mainRightColumnTitle}`}>
          <h6>{data?.middleCol?.leftCol?.top?.link_text}</h6>
        </div>
        <Flex direction="column" gap="20px" className={styles.blogPreviews}>
          {data?.middleCol?.leftCol?.bottom?.children?.articles.map(
            (article: Article, index: number) => (
              <ZbLink size="large" key={index} to={article.url} type="unstyled">
                <BlogPreview
                  image={article.image}
                  title={article.title}
                  text={article.text}
                  date={article.date}
                />
              </ZbLink>
            )
          )}
          <ZbLink to={data?.middleCol?.leftCol?.top.url} type="base">
            {data?.middleCol?.leftCol?.top?.link_text}
          </ZbLink>
        </Flex>
      </Flex>
      <Flex
        direction="column"
        width="100%"
        gap="20px"
        mt="12px"
        className={`${secondLevelStyles.mainRightColumn}`}
      >
        <Flex direction="column" gap="20px" justify="start" ml="30px">
          <div className={`${styles.mainRightColumnTitle}`}>
            <h4>{data?.rightCol?.title}</h4>
          </div>
          <Flex
            wrap="wrap"
            align="center"
            justify="start"
            flexGrow="1"
            flexBasis="1"
            flexShrink="50%"
            gap="15px"
            className={`${styles.pillsContainer}`}
          >
            {data?.rightCol?.categories?.map(
              (category: Category, index: number) => (
                <PillButton
                  key={index}
                  text={category.title}
                  link={category.url}
                  id={index.toString()}
                />
              )
            )}
            <PillButton
              key="other"
              text={
                appSettings?.translations?.[`${locale}_blog_otherTopics`] ??
                'Default Text'
              }
              link={data?.middleCol?.leftCol?.top.url ?? '/default-url'}
              id="other"
            />
          </Flex>
        </Flex>
        <Flex direction="column" gap="20px" ml="30px">
          <div className={`${styles.mainRightColumnTitle}`}>
            <h4>{data?.rightCol?.title_bottom}</h4>
          </div>
          <Flex
            direction="column"
            gap="15px"
            className={`${styles.linksContainer}`}
          >
            {data?.rightCol?.links?.map((link: Link, index: number) => (
              <ZbLink key={index} to={link.url} type="base" color="gold">
                <span>{link.title}</span>
              </ZbLink>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </div>
  )
}
