'use client'

import * as ScrollArea from '@radix-ui/react-scroll-area'
import SVGChevron from '@/components/SVGIcons/SVGChevron'
import ZbLink from '@/components/ZbLink'
import clsx from 'clsx'
import styles from './toolbar.module.css'
import { Flex } from '@radix-ui/themes'
import { useEffect, useRef, useState } from 'react'
import { usePathname } from '@/i18n/routing'
import { useResources } from '@/context/ResourcesContext'

type Breadcrumb = {
  title: string
  url: string
}

export default function Toolbar({ alternateNav }: any) {
  const pathname = usePathname()
  const resources = useResources()
  const [isScrolled, setIsScrolled] = useState(false)
  const splitPath = pathname.split('/')
  const firstPathElement = `/${splitPath[1]}`
  const activeElementRef = useRef<HTMLAnchorElement | null>(null)

  const matchingItem = alternateNav.find(
    (item) => item.url === firstPathElement
  )

  const breadcrumbBuild = () => {
    const breadcrumbs: Breadcrumb[] = []
    let found = false

    if (matchingItem) {
      breadcrumbs.push({
        title: matchingItem.title,
        url: matchingItem.url,
      })

      if (matchingItem.children?.length > 0) {
        matchingItem.children.forEach((child) => {
          if (found) return

          if (child.url === pathname) {
            breadcrumbs.push({
              title: child.title,
              url: child.url,
            })
            found = true
          } else if (child.children?.length > 0) {
            child.children.forEach((subchild) => {
              if (found) return

              if (subchild.url === pathname) {
                breadcrumbs.push({
                  title: child.title,
                  url: child.url,
                })
                breadcrumbs.push({
                  title: subchild.title,
                  url: subchild.url,
                })
                found = true
              }
            })
          }
        })
      }
    }

    return breadcrumbs
  }

  const breadcrumbs = breadcrumbBuild()

  useEffect(() => {
    if (activeElementRef) {
      activeElementRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      })
    }
  }, [pathname])

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 5) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div
      className={clsx(
        styles.ToolbarContainer,
        isScrolled ? styles.ToolbarContainerScrolled : ''
      )}
    >
      <div className={clsx(styles.ToolbarBreadcrumbsContainer)}>
        {breadcrumbs.map((breadcrumb, index) => (
          <div key={breadcrumb?.title}>
            <ZbLink
              type="unstyled"
              to={breadcrumb?.url}
              className={clsx(
                styles.ToolbarBreadcrumb,
                index !== 0 ? styles.hideOnDesktop : ''
              )}
            >
              <span>{breadcrumb?.title}</span>
            </ZbLink>
            {index < breadcrumbs.length - 1 && (
              <SVGChevron size={{ width: '10px', height: '10px' }} />
            )}
          </div>
        ))}
      </div>
      <Flex
        className={clsx(styles.ToolbarElements, 'xsDisplayNone')}
        direction="row"
        gap="20px"
        justify="center"
      >
        {matchingItem?.children?.length < 3 ? (
          <>
            <ZbLink
              size="fit-content"
              key={firstPathElement}
              type="unstyled"
              to={firstPathElement}
              className={clsx(
                pathname === firstPathElement && styles.ActiveToolbarElement,
                styles.ToolbarElement
              )}
              ref={pathname === firstPathElement ? activeElementRef : null}
            >
              <span
                className={clsx(
                  pathname === firstPathElement && styles.activeToolbarElement,
                  styles.toolbarElement
                )}
              >
                {resources.general_overview}
              </span>
            </ZbLink>
            {matchingItem?.children?.map((child) => (
              <ZbLink
                size="fit-content"
                key={child.id}
                type="unstyled"
                to={child.url}
                className={clsx(
                  pathname === child.url && styles.ActiveToolbarElement,
                  styles.ToolbarElement
                )}
                ref={pathname === child.url ? activeElementRef : null}
              >
                <span>{child.title}</span>
              </ZbLink>
            ))}
          </>
        ) : (
          <ScrollArea.Root className={styles.ToolbarScrollarea}>
            <ScrollArea.Viewport className={styles.ToolbarViewport}>
              <ZbLink type="unstyled" to={firstPathElement} size="fit-content">
                <span
                  className={clsx(
                    pathname === firstPathElement &&
                      styles.ActiveToolbarElement,
                    styles.ToolbarElement
                  )}
                >
                  {resources.general_overview}
                </span>
              </ZbLink>
              {matchingItem?.children?.map((child) => (
                <ZbLink
                  key={child.title}
                  size="fit-content"
                  type="unstyled"
                  to={child.url}
                  className={clsx(
                    pathname === child.url && styles.ActiveToolbarElement,
                    styles.ToolbarElement
                  )}
                  ref={pathname === child.url ? activeElementRef : null}
                >
                  <span>{child.title}</span>
                </ZbLink>
              ))}
              <ScrollArea.Scrollbar orientation="horizontal">
                <ScrollArea.Thumb data-state="hidden" />
              </ScrollArea.Scrollbar>
            </ScrollArea.Viewport>
          </ScrollArea.Root>
        )}
      </Flex>
    </div>
  )
}
