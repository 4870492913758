import styles from './svgicons.module.css'

export default function SVGLinkedin({
  size = { width: '18px', height: '10px' },
  color = 'white',
  bgColor = 'black',
  hasHighlight = false,
}: {
  size?: { width: string; height: string }
  color?: string
  bgColor?: string
  hasHighlight?: boolean
}) {
  return (
    <svg
      width={size.width}
      height={size.height}
      viewBox="0 0 600 600"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <circle
        fill={bgColor}
        cx="300"
        cy="300"
        r="300"
        transform="translate(-124.26 300) rotate(-45)"
        className={hasHighlight ? styles.highlightOnHover : ''}
      />
      <path
        fill={color}
        fillRule="evenodd"
        d="M155.74,250.68h59.62v200.2h-59.62V250.68Zm29.44-101.57c19.87,0,36.07,16.19,36.07,36.07s-16.19,36.07-36.07,36.07-35.33-16.19-35.33-36.07,15.46-36.07,35.33-36.07h0Zm65.51,101.57h58.88v27.97c12.51-18.4,33.12-30.18,56.68-30.18h16.19c37.54,0,67.72,30.91,67.72,67.72v134.7h-58.88v-18.4h0v-89.8h0c-.74-22.82-16.19-40.48-39.01-40.48h0c-22.82,0-41.95,17.67-42.69,40.48h0v108.2h-58.88V250.68Z"
      />
    </svg>
  )
}
