'use client'

import { Flex } from '@radix-ui/themes'
import styles from './services.module.css'
import SimpleTextBox from '@/components/SimpleTextBox'
import secondLevelStyles from '../secondlevel.module.css'
import ImageTextBox from '@/components/ImageTextBox'
import { imageDomain } from '@/lib/config'
import { useAppSettings } from '@/context/AppSettingsContext'
import { useLocale } from 'next-intl'
import clsx from 'clsx'

export default function Services({ data }: { data: any }) {
  const { appSettings } = useAppSettings()
  const locale = useLocale()

  return (
    <div className={secondLevelStyles.secondLevelContent}>
      <div className={secondLevelStyles.mainLeftColumn}>
        <SimpleTextBox
          title={{
            text: data?.leftCol?.title?.text,
            size: 'h3',
            url: data?.leftCol?.title?.url,
          }}
          content={{ text: data?.leftCol?.text, size: 'md' }}
          link={{
            text: data?.leftCol?.url?.text,
            url: data?.leftCol?.url?.url,
          }}
        />
      </div>
      <Flex
        direction="row"
        gap="25px"
        className={clsx(secondLevelStyles.mainMiddleColumn, styles.middleCol)}
        width="100%"
        justify="center"
      >
        <ImageTextBox
          redirect
          image={{
            url: imageDomain + data?.middleCol?.leftCol?.top?.image,
            size: {
              w: 290,
              h: 230,
            },
          }}
          imgZoom
          title={{ text: data?.middleCol?.leftCol?.top?.title, size: 'h5' }}
          text={data?.middleCol?.leftCol?.top?.text}
          action={{
            text: data?.middleCol?.leftCol?.top?.link_text,
            url: data?.middleCol?.leftCol?.top?.url,
            type: 'button',
          }}
        />
        <ImageTextBox
          redirect
          image={{
            url: imageDomain + data?.middleCol?.rightCol?.top?.image?.url,
            size: {
              w: 290,
              h: 230,
            },
          }}
          imgZoom
          title={{ text: data?.middleCol?.rightCol?.top?.title, size: 'h5' }}
          text={data?.middleCol?.rightCol?.top?.text}
          action={{
            text: data?.middleCol?.rightCol?.top?.link_text,
            url: data?.middleCol?.rightCol?.top?.url,
            type: 'button',
          }}
        />
      </Flex>
      <Flex
        direction="column"
        width="100%"
        gap="20px"
        className={secondLevelStyles.mainRightColumn}
      >
        <div className={styles.mainRightColumnTitle}>
          <h6>{appSettings?.translations?.[`${locale}_menu_moreServices`]}</h6>
        </div>
        <Flex
          direction="column"
          gap="25px"
          pr="20px"
          pl="20px"
          className={styles.mainRightColumnContent}
        >
          {data?.rightCol?.children.map((item, index) => (
            <SimpleTextBox
              key={index}
              title={{ text: item.title, size: 'h5' }}
              content={{ text: item.text, size: 'sm' }}
              link={{
                text:
                  appSettings?.translations?.[`${locale}_menu_learnMore`] || '',
                url: item.url || '',
              }}
              gap="5px"
            />
          ))}
        </Flex>
      </Flex>
    </div>
  )
}
