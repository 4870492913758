'use client'

import { useEffect, useState } from 'react'
import Image from 'next/image'
import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import * as Separator from '@radix-ui/react-separator'
import { Flex } from '@radix-ui/themes'
import styles from './navigation.module.css'
import CountrySwitch from '@/components/CountrySwitch'
import LangSwitch from '@/components/LangSwitch'
import ZbLink from '@/components/ZbLink'
import SVGHamburger from '@/components/SVGIcons/SVGHamburger'
import FirstLevel from '@/components/ZbNavigation/FirstLevel'
import { usePathname } from '@/i18n/routing'
import { NAV_MENU_IDS } from '@/components/ZbNavigation/navigationIDs'
import { useResources } from '@/context/ResourcesContext'
import Toolbar from '@/components/ZbNavigation/Toolbar'
import clsx from 'clsx'
import SearchInput from '@/components/SearchInput'
import SVGSearch from '@/components/SVGIcons/SVGSearch'

type ZbNavigationProps = {
  navData: any
  alternateNav: any
}

export default function ZbNavigation({
  navData,
  alternateNav,
}: ZbNavigationProps) {
  const pathname = usePathname()
  const firstPathElement = `/${pathname.split('/')[1]}`
  const resources = useResources()
  const countryCode = process.env.NEXT_PUBLIC_COUNTRY_CODE
  const [navValue, setNavValue] = useState('')
  const [isScrolled, setIsScrolled] = useState(false)
  const [isSearchExpanded, setIsSearchExpanded] = useState(false)

  const showToolbar =
    pathname !== '/' &&
    !pathname.includes('search-results') &&
    !pathname.startsWith('/blog') &&
    !pathname.includes('cookies') &&
    !pathname.includes('/404')

  useEffect(() => {
    setNavValue('')
  }, [pathname])

  const closeNavigation = (value: string) => {
    setNavValue(value)
  }

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 5) {
        setIsScrolled(true)
      } else {
        setIsScrolled(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const handleSearchExpand = (expanded: boolean) => {
    setIsSearchExpanded(expanded)
  }

  return (
    <header
      className={clsx(isScrolled ? styles.HeaderScrolled : styles.Header)}
      role="navigation"
    >
      <Flex direction="row" justify="center" className={styles.NavWrapper}>
        <div
          className={clsx(
            styles.NavContainer,
            isScrolled ? styles.NavContainerScrolled : '',
            isSearchExpanded ? styles.NavContainerSearchExpanded : ''
          )}
        >
          <figure
            className={clsx(
              styles.NavLogoContainer,
              isScrolled ? styles.NavLogoContainerSticky : ''
            )}
          >
            <ZbLink to="/">
              <Image
                id="logo"
                src="/logo.svg"
                width={170}
                height={170}
                alt="Logo to Home"
                className={clsx(
                  styles.NavLogo,
                  isScrolled ? styles.NavLogoSmall : ''
                )}
              />
            </ZbLink>
          </figure>
          {isSearchExpanded ? (
            <div className={styles.ExpandedSearchContainer}>
              <SearchInput onClose={() => handleSearchExpand(false)} />
            </div>
          ) : (
            <NavigationMenu.Root
              value={navValue}
              onValueChange={setNavValue}
              className={isScrolled ? styles.NavRootSticky : styles.NavRoot}
            >
              <NavigationMenu.List
                className={clsx(
                  styles.NavFirstLevel,
                  countryCode === 'es' && styles.NavFirstLevelGap
                )}
              >
                {navData?.map((firstLevel: any, key: number) => {
                  if (firstLevel?.drupalId !== NAV_MENU_IDS.HAMBURGER_MENU) {
                    return (
                      <FirstLevel
                        key={key}
                        closeNavigation={closeNavigation}
                        firstLevelData={firstLevel}
                        isScrolled={isScrolled}
                        parentKey={key}
                        isActive={pathname.includes(
                          firstLevel?.leftCol?.url?.url
                        )}
                      >
                        <ZbLink to={firstLevel?.leftCol?.url?.url}>
                          {firstLevel?.leftCol?.title?.text}
                        </ZbLink>
                      </FirstLevel>
                    )
                  }
                })}
                {resources && (
                  <NavigationMenu.Item
                    className={clsx(
                      styles.NavFirstLevelItem,
                      'xsDisplayNone',
                      'removeUnderline',
                      styles.noHoverAnimation,
                      styles.NavTriggerUnstyle,
                      isScrolled ? styles.onScrollHeightAdjust : ''
                    )}
                  >
                    <ZbLink
                      to={resources['volumecalc_url']}
                      isExternal
                      type="button"
                      size="large"
                    >
                      <span>{resources['volumecalc_rentABox']}</span>
                    </ZbLink>
                  </NavigationMenu.Item>
                )}
                <NavigationMenu.Item
                  className={clsx(
                    styles.NavFirstLevelItem,
                    styles.NavTriggerUnstyle
                  )}
                >
                  <button
                    className={styles.SearchTrigger}
                    onClick={() => handleSearchExpand(true)}
                  >
                    <SVGSearch
                      size={{ width: '22px', height: '22px' }}
                      color="var(--primary-2)"
                    />
                  </button>
                </NavigationMenu.Item>
                <NavigationMenu.Item
                  className={clsx(
                    styles.NavFirstLevelItem,
                    styles.countrySwitchContainer,
                    'xlDisplayNone'
                  )}
                >
                  <Flex>
                    <CountrySwitch />
                  </Flex>
                </NavigationMenu.Item>
                <NavigationMenu.Item
                  className={clsx(
                    styles.NavFirstLevelItem,
                    styles.langSwitchContainer,
                    'xlDisplayNone'
                  )}
                >
                  <LangSwitch />
                </NavigationMenu.Item>
                <NavigationMenu.Item className={clsx(styles.NavFirstLevelItem)}>
                  <Separator.Root
                    orientation="vertical"
                    style={{
                      width: '1px',
                      height: '60px',
                      margin: '0',
                      backgroundColor: '#D2D2D2',
                    }}
                  />
                </NavigationMenu.Item>
                <FirstLevel
                  closeNavigation={closeNavigation}
                  firstLevelData={navData?.find(
                    (item: any) =>
                      item?.drupalId === NAV_MENU_IDS.HAMBURGER_MENU
                  )}
                  alternateNav={alternateNav}
                  parentKey={486}
                  isScrolled={isScrolled}
                  openOnClick
                >
                  <SVGHamburger
                    size={{
                      width: '24px',
                      height: '28px',
                    }}
                    color="black"
                  />
                </FirstLevel>
              </NavigationMenu.List>
            </NavigationMenu.Root>
          )}
        </div>
      </Flex>
      {showToolbar && (
        <Flex
          direction="row"
          align="center"
          justify="center"
          className={
            !isScrolled
              ? styles.ToolbarWrapper
              : clsx(styles.ToolbarWrapper, styles.ToolbarWrapperScrolled)
          }
        >
          <Toolbar alternateNav={alternateNav} />
        </Flex>
      )}
    </header>
  )
}
